





































































































































































import { Component, Vue } from 'vue-property-decorator';
import 'vue-good-table/dist/vue-good-table.css';
import CanzoneDataService from "../services/CanzoneDataService";
import CanzoneRow from '../model/CanzoneRow';
import CanzoneEntryPoint from "../EntryPoint/CanzoneEntryPoint";

import { namespace } from "vuex-class";
const Auth = namespace("Auth");

@Component({
    components: {
        'vue-good-table': require('vue-good-table').VueGoodTable,
    }
})
export default class RicercaCanzone extends Vue {

    @Auth.State("user")
    private currentUser!: any;

    @Auth.State("nameidentifier")
    private userId!: any;

    @Auth.State("status")
    private status!: any;

    @Auth.Action
    public signOut!: () => void;

    get IsLogged() {
        return this.status.loggedIn;
    }

    isOwner(userId: any) {
        return this.IsLogged && this.userId == userId;
    }

    canModificaCanzone(userId: any) {
        return this.isOwner(userId);
    }

    canDeleteCanzone(userId: any) {
        return this.isOwner(userId);
    }


    // Initial data can be declared as instance properties
    private pageTitle: string = 'Ricerca Canzone';

    private sortBy: string = "titolo";
    private sortDesc: boolean = false;
    private sortDirection: any = "asc";

    private totalRows: number = 1;
    private currentPage: number = 1;
    private perPage: number = 10;
    private pageOptions: any = [5, 10, 15, { value: 100, text: "Show a lot" }];

    private filter: any = null;
    private filterOn: any = ["titolo"];

    private filters: any = {
        titolo: '',
        autore: '',
        partiDellaMessa: '',
        tempiLiturgici: '',
        paroleChiave: '',
    };

    private modalShow: boolean = false;
    private feedback: string = "";
    private feedbackshow: boolean = false;
    private feedbackAlertType: string = "";

    private Columns: Array<any> = [
        {
            label: "Titolo",
            key: "titolo",
            sortable: true
        },
        {
            label: "Autore",
            key: "autore",
            sortable: true
        },
        {
            label: "Tempi della Messa",
            key: "partiDellaMessa",
            sortable: true
        },
        {
            label: "Tempi Liturgici",
            key: "tempiLiturgici",
            sortable: true
        },
        {
            label: "Parole chiave",
            key: "paroleChiave",
            sortable: true
        },
        {
            label: "Visibilita'",
            key: "visibilityPolicy",
            sortable: true,
            formatter: (value: any) => {
                return value == 1 ? "Privata" : "Pubblica";
            }
            //filterByFormatted: true
        },
        {
            label: "Is Owner",
            key: "isOwner",
            sortable: true
        },
        {
            label: "Doc.",
            key: "hasAllegatiDoc",
            sortable: true
        },
        {
            label: "Audio",
            key: "hasAllegatiAudio",
            sortable: true
        },
        {
            key: 'azioni',
            label: 'Azioni',
            sortable: false
        },
    ];


    private Rows: CanzoneRow[] = [];

    getCanzoni() {
        CanzoneEntryPoint.getAll().then((data: any) => {
            //console.log("data-->" + data);
            this.Rows = data;
            this.totalRows = data.length;
            //console.log(this.Rows);
        })
            .catch((e: any) => {
                this.feedbackshow = true;
                this.feedbackAlertType = "danger";
                this.feedback = e.message;
                console.log(e);
            });
    }

    private idToDelete: string = '';
    showModalDelete(id: string) {
        this.idToDelete = id;
        (this.$refs['modaleDelete'] as any).show()
        //this.$bvModal.show('modaleDelete');
    };
    hideModal() {
        (this.$refs['modaleDelete'] as any).hide()
        //this.$bvModal.hide('modaleDelete');
    };

    deleteRow() {
        CanzoneDataService.delete(this.idToDelete)
            .then((response) => {
                //this.canzone.id = response.data.id;
                //this.feedbackItem = new FeedbackItem(true, true);
                this.feedbackshow = true;
                this.feedbackAlertType = "success";
                this.feedback = "Canzone eliminata con successo";
                this.getCanzoni();
                //this.cleancCanzoneFormGroup();
                //console.log(response.data);
            })
            .catch((e) => {
                this.feedbackshow = true;
                this.feedbackAlertType = "danger";
                this.feedback = e.message;
                console.log(e);

                if (e.response.status === 401) {
                    this.signOut();
                    this.$router.push("/login");
                }
            });

        this.hideModal();
    }

    onFiltered(filteredItems: any) {
        //console.log(this.filter)
        //console.log(filteredItems.length)
        // Trigger pagination to update the number of buttons/pages due to filtering
        this.totalRows = filteredItems.length
        //this.currentPage = 1
    }

    filtered() {
        const filtered = this.Rows.filter(row => {
            return Object.keys(this.filters).every(key =>
                String(row[key]).toLowerCase().includes(this.filters[key].toLowerCase()))
        })
        return filtered.length > 0 ? filtered : [{
            titolo: '',
            autore: '',
            partiDellaMessa: '',
            tempiLiturgici: '',
            paroleChiave: '',
            hasAllegatiDoc: false,
            hasAllegatiAudio: false
        }]
    }

    mounted() {
        this.getCanzoni();
    }

}

